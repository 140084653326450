@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-Regular'), url('../assets/fonts/NunitoSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-Italic'), url('../assets/fonts/NunitoSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-ExtraLight'), url('../assets/fonts/NunitoSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-ExtraLightItalic'), url('../assets/fonts/NunitoSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-Light'), url('../assets/fonts/NunitoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-LightItalic'), url('../assets/fonts/NunitoSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-SemiBold'), url('../assets/fonts/NunitoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-SemiBoldItalic'), url('../assets/fonts/NunitoSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-Bold'), url('../assets/fonts/NunitoSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-BoldItalic'), url('../assets/fonts/NunitoSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-ExtraBold'), url('../assets/fonts/NunitoSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-ExtraBoldItalic'), url('../assets/fonts/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-Black'), url('../assets/fonts/NunitoSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Sans';
    src: local('NunitoSans-BlackItalic'), url('../assets/fonts/NunitoSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}